import React, { useEffect, useState, useRef } from "react"
import Introduction from "../components/spapage/Introduction"
import SpaComponent from "../components/spapage/SpaComponent"
import ContactUs from "../components/spapage/ContactUs"
import SpaData from "../data/spa-page.json"
import Sauna from "../images/inside-sauna.jpg"
import Jaccuzzi from "../images/inside-jaccuzzi.jpg"
import Massage from "../images/massage.jpg"
import { connect } from "react-redux"
import SEO from "../components/seo"
import { ScrollTrigger } from "gsap/ScrollTrigger"

const letterUrl = {
  S: Sauna,
  P: Jaccuzzi,
  A: Massage,
}

const langSeo = {
  fr: {
    title: "Hôtel Au Charme Rabelaisien **** | Espace détente",
    description:
      "Hotel Au Charme Rabelaisien, votre espace détente privé pour relaxer après vos visites.",
  },
  en: {
    title: "Hôtel Au Charme Rabelaisien **** | Spa",
    description:
      "Hotel Au Charme Rabelaisien, your private relaxation area to relax after your visits.",
  },
  ru: {
    title: "Hôtel Au Charme Rabelaisien **** | Spa",
    description:
      "Hotel Au Charme Rabelaisien, your private relaxation area to relax after your visits.",
  },
}

const SpaPage = ({
  updateNavColor,
  navColor,
  language,
  updateShowArrowDown,
  scrollOffset,
  scrolltriggerReady,
}) => {
  //show arrow down
  let tempScrollTriggerSpaRef = useRef(null)
  //state
  const [pageHeight, setPageHeight] = useState("")

  useEffect(() => {
    if (scrolltriggerReady) {
      if (pageHeight && pageHeight - scrollOffset < window.innerHeight + 100) {
        updateShowArrowDown(false)
      } else {
        updateShowArrowDown(true)
      }
    }
  }, [scrollOffset, scrolltriggerReady])

  let urlLanguage = "fr"

  if (typeof window !== "undefined") {
    const url = window.location.pathname

    if (url.includes("fr")) {
      urlLanguage = "fr"
    } else {
      urlLanguage = "en"
    }
  }

  useEffect(() => {
    navColor !== "black" && updateNavColor()
    const tempScrollTrigger = ScrollTrigger.create({ id: "temp-rooms" })
    setPageHeight(tempScrollTrigger["scroller"]["scrollHeight"])
    tempScrollTriggerSpaRef.current = tempScrollTrigger

    return () => {
      tempScrollTriggerSpaRef.current && tempScrollTriggerSpaRef.current.kill()
    }
  }, [])
  return (
    <div>
      <SEO
        title={langSeo[urlLanguage]["title"]}
        description={langSeo[urlLanguage]["description"]}
      />
      <Introduction />
      {SpaData[language].map(spaObj => {
        return (
          <SpaComponent
            key={spaObj.src}
            src={spaObj.src}
            letterSrc={spaObj.letterSrc}
            letterLeftPosition={spaObj.letterLeftPosition}
            letterTopPosition={spaObj.letterTopPosition}
            title={spaObj.title}
            description={spaObj.description}
            leftMargin={spaObj.leftMargin}
            letterWidthCoef={spaObj.letterWidthCoef}
            letterUrl={letterUrl[spaObj.letter]}
            letter={spaObj.letter}
            price={spaObj.price}
          />
        )
      })}
      <ContactUs />
    </div>
  )
}

const mapStateToProps = ({
  navColor,
  language,
  scrollOffset,
  scrolltriggerReady,
}) => {
  return { navColor, language, scrollOffset, scrolltriggerReady }
}

const mapDispatchToProps = dispatch => {
  return {
    updateNavColor: () =>
      dispatch({ type: `UPDATE_NAV_COLOR`, payload: "black" }),
    updateShowArrowDown: bool =>
      dispatch({ type: "UPDATE_SHOW_ARROW_DOWN", payload: bool }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SpaPage)
