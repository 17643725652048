import { Trans } from "@lingui/macro"
import TransitionLink from "gatsby-plugin-transition-link"
import React, { useRef } from "react"
import { connect } from "react-redux"
import { Image } from "../gatsby-images/image"
import Footer from "../share/Footer"

const ContactUs = ({
  showTransitionBanner,
  hideTransitionBanner,
  resetOffset,
  isTouchScreen,
  roomListColor,
  language,
}) => {
  let footerContainer = useRef()
  let contact = useRef()

  //tweens
  // let footerTween = useRef(null)

  // useEffect(() => {
  //     if(scrolltriggerReady) {
  //         let height = window.innerHeight

  //         gsap.set(footerContainer, { yPercent: -80 });

  //         const uncover = gsap.timeline({ paused:true })

  //         uncover
  //         .to(footerContainer, { yPercent: 0, ease: 'none' })
  //         ;

  //         ScrollTrigger.getById('spa-footer') && ScrollTrigger.getById('spa-footer').kill()
  //         footerTween.current = ScrollTrigger.create({
  //           id: 'spa-footer',
  //           trigger: contact,
  //           start: '70% 70%',
  //           end: `bottom 60%`,
  //           animation: uncover,
  //           scrub: true,
  //         })
  //     }
  // }, [scrolltriggerReady])

  // useEffect(() => {
  //     return () => {
  //         footerTween.current && footerTween.current.kill()
  //     }
  // }, [])
  return (
    <>
      <div
        className="contact-us-spa row"
        style={{
          paddingBottom: isTouchScreen ? "10vh" : null,
          height: isTouchScreen ? "50vh" : "40vh",
          position: "relative",
          zIndex: 1,
          backgroundColor: roomListColor,
          display: "flex",
          alignItems: "center",
        }}
      >
        <div className="text  col-sm-4 offset-sm-2 ">
          <div className="title">
            <Trans>Besoin de plus d'informations?</Trans>
          </div>
          {/* <div className="text">

                    </div> */}
          <div style={{ width: "100%" }} onClick={() => showTransitionBanner()}>
            <TransitionLink
              to={`/${language}/contact`}
              exit={{
                delay: 2,
              }}
              entry={{
                trigger: ({ node, e, exit, entry }) => {
                  hideTransitionBanner()
                  resetOffset(true)
                },
              }}
            >
              <div className="button">
                <Trans>Contactez-nous</Trans>
              </div>
            </TransitionLink>
          </div>
        </div>
        <div
          className="image  col-sm-4 d-none d-sm-block"
          ref={el => (contact = el)}
        >
          <Image src="spa-banner" />
        </div>
      </div>
      <div
        style={{
          position: "relative",
          zIndex: 0,
          height: isTouchScreen ? "50vh" : "40vh",
        }}
        ref={el => (footerContainer = el)}
      >
        <Footer />
      </div>
    </>
  )
}

const mapStateToProps = ({
  isTouchScreen,
  scrolltriggerReady,
  roomListColor,
  language,
}) => {
  return { isTouchScreen, roomListColor, scrolltriggerReady, language }
}

const mapDispatchToProps = dispatch => {
  return {
    showTransitionBanner: () => dispatch({ type: `IMG_NOT_LOADED` }),
    hideTransitionBanner: () => dispatch({ type: `IMG_LOADED` }),
    resetOffset: bool => dispatch({ type: "RESET_OFFSET", payload: bool }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs)
