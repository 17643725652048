import gsap from "gsap/gsap-core"
import React, { useEffect, useRef, useState } from "react"
import { Image } from "../gatsby-images/image"
import { Trans } from "@lingui/macro"
import { connect } from "react-redux"
import { ScrollTrigger } from "gsap/ScrollTrigger"

const SpaComponent = ({
  src,
  letterTopPosition,
  letterLeftPosition,
  leftMargin,
  title,
  description,
  letterWidthCoef,
  letterUrl,
  letter,
  price,
  scrolltriggerReady,
}) => {
  let mainImage = useRef(null)
  let letterImg = useRef(null)
  const [letterHeight, setLetterHeight] = useState(100)

  //tweens
  let timelineTween = useRef(null)

  useEffect(() => {
    return () => {
      timelineTween.current && timelineTween.current.kill()
    }
  })

  useEffect(() => {
    setLetterHeight(mainImage.getBoundingClientRect().height * 2)
    const mainImageConst = mainImage.children[0]
    const letterImgConst = letterImg

    if (scrolltriggerReady) {
      let tl = gsap.timeline()

      ScrollTrigger.getById("spa-page-animation") &&
        ScrollTrigger.getById("spa-page-animation").kill()

      gsap.set(mainImageConst, { scale: 1.5, y: 15 })
      timelineTween.current = gsap.to([mainImageConst], {
        scrollTrigger: {
          id: `spa-page-animation-${src}`,
          trigger: mainImageConst,
          start: "top 80%",
          end: "bottom 10%",
          scrub: true,
          ease: "linear",
          //events: onEnter onLeave onEnterBack onLeaveBack
          toggleActions: "play none reverse none",
          //options: play, pause, resume, reset, restart, complete, reverse,none
        },
        yPercent: -15,
      })
    }
  }, [scrolltriggerReady])
  return (
    <div className="spa-component">
      <div className="offset-1">
        <div style={{ marginLeft: leftMargin }}>
          <div className="main-image-container">
            <div
              style={{ overflow: "hidden", position: "relative", zIndex: 1 }}
            >
              <div ref={el => (mainImage = el)}>
                <Image src={src} />
              </div>
            </div>
            <div
              className="letter-image"
              style={{
                height: letterHeight,
                width: letterHeight * letterWidthCoef,
                left: letterLeftPosition,
                top: letterTopPosition,
              }}
            >
              <svg
                viewBox={`0 0 ${letterHeight} ${letterHeight}`}
                height={letterHeight}
                width={letterHeight}
              >
                <clipPath id={`c-mask-${letter}`}>
                  <text
                    ref={el => (letterImg = el)}
                    textAnchor="middle"
                    alignmentBaseline="central"
                    x="25%"
                    y="50%"
                    style={{ fontFamily: "Times New Roman" }}
                    data-svg-origin="158.671875 44.515625"
                    transform="matrix(1,0,0,1,0,16.125737712610906)"
                  >
                    {letter}
                  </text>
                </clipPath>
                <image
                  className="c-object"
                  preserveAspectRatio="none"
                  style={{ clipPath: `url("#c-mask-${letter}")` }}
                  xlinkHref={letterUrl}
                  clipPath={`url(#c-mask-${letter})`}
                  height={letterHeight}
                  width={letterHeight}
                ></image>
              </svg>
            </div>
          </div>
          <div className="text">
            <div className="row">
              <div className="title">{title}</div>
            </div>
            <div className="row">
              <div className="description">{description}</div>
              <div className="price">
                <span
                  style={{ textDecoration: "underline", fontWeight: "bold" }}
                >
                  <Trans>Prix</Trans>
                </span>
                <span
                  style={{ marginLeft: 5, fontSize: 16, fontWeight: "bold" }}
                >
                  : {price}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ scrolltriggerReady }) => {
  return { scrolltriggerReady }
}

export default connect(mapStateToProps, null)(SpaComponent)
