import React from "react"
import { Trans } from "@lingui/macro"

const Introduction = () => {
  return (
    <div className="spa-introduction row">
      <div className="offset-1 col-sm-6">
        <div className="title">SPA</div>
        <div className="subtitle">
          <Trans>
            Pour ceux qui souhaitent faire une pause relaxante pendant leur
            séjour, il y a un spa confortable à l’intérieur de la structure.
            Idéal pour se détendre le corps et l’esprit après une journée de
            visites ou pour récupérer de l’énergie.
          </Trans>
          <div>
            <span style={{ textDecoration: "underline", fontWeight: "bold" }}>
              <Trans>Intime et réservé</Trans>
            </span>
            <Trans>
              , notre spa est conçu pour accueillir un nombre limité de deux
              clients qui pourront profiter pleinement des espaces
            </Trans>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Introduction
